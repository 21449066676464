import React, { Component } from 'react';
import styles from './App.module.scss';
import './global.scss';

import RequestServies from '../helpers/requestServices';
import { Redirect } from 'react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const api = new RequestServies({});
// const SignInComponent = React.lazy(() => import('../Authorization/SignIn/SignInComponent'));

// const RegistrationComponent = React.lazy(
//   () => import('../Authorization/Registration/RegistrationContainer'),
// );

const MainComponent = React.lazy(() => import('../Main/MainComponent'));

// const ForgotPasswordComponent = React.lazy(
//   () => import('../Authorization/SignIn/ForgotPassword/ForgotPasswordComponent'),
// );

// const ResetPasswordComponent = React.lazy(
//   () => import('../Authorization/SignIn/ForgotPassword/ResetPasswordComponent'),
// );

// const HotspotsComponent = React.lazy(() => import('../Hotspots/HotspotsComponent'));

// const CorrectionComponent = React.lazy(() => import('../FloorPlanCorrection/CorrectionComponent'));

interface IAppProps {
  history: History;
  uuid: string;
  redirectOld: boolean;
  getAccountsData: () => void;
}

interface IAppState {
  token: string,
}

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      token: ''
    };
  }

  componentDidMount = () => {
    api.loginApp();
    this.props.getAccountsData();
    const list: any = document.head.children;
    for (const item of list) {
      if (item.tagName === 'LINK') {
        if (item.href.includes('NewOrder')) {
          item.disabled = true;
        }
      }
    }
  };
  
  componentDidUpdate = () => {
    const { history } = this.props;
    if (this.props.redirectOld) {
      window.location.href = process.env.REACT_APP_API_URL as any
    }

  }

  public render() {
    if (window.location.pathname === '/') {
      return <Redirect to="/property" />
    }
    if (!this.props.uuid) {
      return null
    }
    return (
      <div className={styles.App}>
        {/* <ConnectedRouter history={history}>
          <Router history={history}>
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Spin />
                </div>
              }
            > */}
        {/* <Route path="/" exact component={MainComponent} />
         */}
        {/* <Switch>
              </Switch> */}
              <DndProvider backend={HTML5Backend} >

        <MainComponent />
              </DndProvider>
        {/* <Switch>
                <Route path="/" component={MainComponent} />
                <Route path="/signin" component={SignInComponent} />
                <Route path="/registration" component={RegistrationComponent} />
                <Route
                  path="/forgot"
                  component={ForgotPasswordComponent}
                />
                <Route
                  path="/forgot/:code"
                  component={ResetPasswordComponent}
                />
                <Route
                  path="/hotspots/:id"
                  component={HotspotsComponent}
                />
                <Route
                  path="/plan-correction/:id"
                  component={CorrectionComponent}
                />
              </Switch> */}
        {/* </Suspense>
          </Router>
        </ConnectedRouter> */}
      </div>
    );
  }
}

export default App;
