import {
  SET_ADDRESS_DATA,
  SET_OWNER_DATA,
  SET_PACKAGE_DATA,
  CLEAR_PACKAGE_DATA,
  SET_UUID,
  SET_HD_PROPERTY_ID,
  SET_PLANS,
  SET_FORM,
  RESET_NEW_ORDER_DATA,
  SET_HD_ORDER_DATA,
} from "./constans";
import StepData from "../helpers/stepData";
import { ADDRESS, OWNER, PACKAGE } from "../constants/text";
import {IOrderData} from "./types";

const initAddressData = {
  houseNumber: undefined,
  street: "",
  unitNumber: undefined,
  city: "",
  state: undefined,
  zip: undefined,
  plans: {},
  form: null,
};

const initOwnerData = {
  name: "",
  email: "",
  mls: "",
};

const initOrderData = {
  ohdp: {},
  tour_status: {
    text: ''
  }
} as IOrderData;

const initialState = {
  address: initAddressData,
  owner: initOwnerData,
  package: {},
  orderData: initOrderData,
};

const IS_STORAGE_ACTIVATED = false;

const addressStorage = new StepData(ADDRESS);
const ownerStorage = new StepData(OWNER);
const packageStorage = new StepData(PACKAGE);
if (IS_STORAGE_ACTIVATED) {
  initialState.address = addressStorage.get() || initialState.address;
  initialState.owner = ownerStorage.get() || initialState.owner;
  initialState.package = packageStorage.get() || initialState.owner;
}

const HomeDiaryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_HD_ORDER_DATA:
      return {
        ...state,
        orderData: {
          ...state.orderData,
          ...action.payload
        }
      };
    case SET_ADDRESS_DATA:
      IS_STORAGE_ACTIVATED && addressStorage.add(action.payload);
      return {
        ...state,
        address: action.payload,
      };
    case SET_OWNER_DATA:
      IS_STORAGE_ACTIVATED && ownerStorage.add(action.payload);
      return {
        ...state,
        owner: action.payload,
      };
    case SET_PACKAGE_DATA:
      IS_STORAGE_ACTIVATED && packageStorage.add(action.payload);
      return {
        ...state,
        package: {
          ...state.package,
          ...action.payload
        },
      };
    case CLEAR_PACKAGE_DATA:
      IS_STORAGE_ACTIVATED && packageStorage.add({});
      return {
        ...state,
        package: {},
      };
    case RESET_NEW_ORDER_DATA:
      return {
        ...state,
        address: {},
        owner: {},
        package: {},
        plans: {},
        form: null,
      };
    case SET_UUID:
      return {
        ...state,
        uuid: action.payload,
      };
    case SET_HD_PROPERTY_ID:
      return {
        ...state,
        propertyId: action.payload,
      };
    case SET_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case SET_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default HomeDiaryReducer;
